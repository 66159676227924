import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["message"]

  onSearchSuccess(event) {
    let [data, status, xhr] = event.detail;
    // console.log(xhr.response)
    location.href = xhr.response;
  }

  onSearchError(event) {
    // let [data, status, xhr] = event.detail;
    this.messageTarget.innerHTML = `<span>ご入力いただいた求人番号が見つかりませんでした。<br>以下の内容が考えられます。詳しくは<a href="https://shiraha.tayori.com/faq/41485aa3ae6b4f78d2e4bb253290329c059ba807/category/c9c138c6157ca5af6ac6517b15b709af7812603b" target="_blank">こちら</a>をご覧ください。</span><br>
    <div>
      <ul>
        <li>入力された番号にミスがある</li>
        <li>求人票の有効期限が切れている</li>
        <li>民間人材ビジネスへの求人情報提供を許可していない</li>
      </ul>
    </div>
    `
  }

  clearMessage(event) {
    event.preventDefault()
    this.messageTarget.innerHTML = ""
  }
}
